
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { searchPatientPayers } from "@/api/patientPayer.api";
import { useStore } from "vuex";
import { getDictionary } from "@/api/dictionaries.api";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "../common/useFilters";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  emptyListError?: string;
  isLoaded: boolean;
  items: any[];
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  globalSearch: string;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    patientPayerPriority: { code: string; description: string }[];
    patientRelationshipToSubscriber: { code: string; description: string }[];
    boolList: { code?: boolean; name: string }[];
  };
  showInactive: boolean;
}
export default defineComponent({
  name: "InsuranceList",
  props: ["patientId"],
  components: { PaginationUi },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const {
      orderList,
      putFiltersToUrl,
      getFiltersFromUrl,
    } = useFilters();

    orderList.value = [
      {
        name: "Payer Name",
        key: "name",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Insurance Phone",
        key: "insurancePhone",
        isFilter: true,
        keyword: null,
      },

      {
        name: "Subscriber ID",
        key: "subscriberIdNumber",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Subscriber Group",
        key: "subscriberGroupNumber",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Effective Date",
        key: "effectiveDate",
        isFilter: true,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "End Date",
        key: "endDate",
        isFilter: true,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Patient Relationship to Subscriber",
        key: "patientRelationshipToSubscriber",
        formType: "patientRelationshipToSubscriber",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Priority",
        key: "responsibility",
        formType: "patientPayerPriority",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      orderBy: "default",
      IsAdvancedOrderBy: true,
      isDecr: false,
      showInactive: false,
      globalSearch: "",
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      dict: {
        patientPayerPriority: [],
        patientRelationshipToSubscriber: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
          { code: undefined, name: "All" },
        ],
      },
    });

    async function cleanFilter() {
      data.globalSearch = "";
      data.orderBy = "default";
      data.IsAdvancedOrderBy = true;
      data.isDecr = false;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      organizationId.value = getOrganization();
      data.dict.patientPayerPriority = store.getters.allPatientPayerPriority;
      data.dict.patientRelationshipToSubscriber = await getDictionary(10, "");
      await getAll();
      window.localStorage.removeItem("AddInsurance");
      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value
            .filter((item) => {
              return !item.IsAdvancedOrderBy;
            })
            .map((item) => {
              return item.key;
            })
        },
        PatientId: props.patientId,
        ShowInactive: data.showInactive,
        GlobalSearch: data.globalSearch,
      };

      const res = await searchPatientPayers(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = orderList.value.filter((f) => {
          return f.keyword != undefined && f.keyword !== "" && f.keyword != null;
        });
        if (currentFilters.length > 0) {
          data.emptyListError = "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
    }

    function selectItem(item) {
      window.localStorage.removeItem("AddInsurance");
      router.push({
        path: "/patientAccounts/patient/" + props.patientId + "/patientInsurance/" + item.id,
      });
    }

    function newInsurance() {
      window.localStorage.removeItem("AddInsurance");
      router.push({
        path: "/patientAccounts/patient/" + props.patientId + "/patientInsurance/",
      });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    function getPatientRelationshipToSubscriber(code) {
      const item = data.dict.patientRelationshipToSubscriber.find((item) => {
        return item.code == code;
      });
      if (item) {
        return item.description;
      }
      return code;
    }

    function getPatientPayerPriority(code) {
      const item = data.dict.patientPayerPriority.find((item) => {
        return item.code == code;
      });
      if (item) {
        return item.description;
      }
      return code;
    }

    function getSubscriberName(item) {
      let name = item.subscriberFirstName;
      if (item.subscriberMiddleInitial) {
        name = name + " " + item.subscriberMiddleInitial;
      }
      name = name + " " + item.subscriberLastName;
      return name;
    }

    async function pageChanged(page){
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      newInsurance,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      getPatientRelationshipToSubscriber,
      getPatientPayerPriority,
      getSubscriberName,
      pageChanged,
      pageSizeChanged,
    };
  },
});
